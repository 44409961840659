import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import SEO from "../components/seo"
import {Container,Row,Col} from 'react-bootstrap'


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Automotive" />
        <div >
            <Image alt="military" filename="automotive-baner.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead">Automotive Engineering & Design Services</h2>
            </Row>
            <hr className="embhr" />
            <Row>
               
                <Col sm={12} lg={6}>
                    <Image alt="military" filename="automotive-inside.png"  />
                </Col>

                <Col sm={12} lg={6} className="servicePagePara">
                Looking to stay on the cutting edge of automotive technology? Our experts have the knowledge and passion to bring your project to a reality. As consumers become more demanding in their needs, the automotive industry has become more competitive and companies must answer the call to higher grade. Let us be the easy to use and scalable technology solution that you need to stay ahead of the pack.
                </Col>
                
               
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

